import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';

import CaseStudySummary from '../../components/CaseStudySummary';
import LayoutBlogPost from '../../components/LayoutBlogPost';
import SEO from '../../components/SEO';
import withI18n from '../../utils/hocs';

const CaseStudyAscenzaLocalSitesPage = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      heroImage: file(relativePath: { eq: "blog/ascenza-local-sites/hero-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      wireframesImage: file(relativePath: { eq: "blog/ascenza-local-sites/wireframes.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      hp1Image: file(relativePath: { eq: "blog/ascenza-local-sites/hp1.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      hp2Image: file(relativePath: { eq: "blog/ascenza-local-sites/hp2.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      hp3Image: file(relativePath: { eq: "blog/ascenza-local-sites/hp3.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      productsImage: file(relativePath: { eq: "blog/ascenza-local-sites/products.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      productImage: file(relativePath: { eq: "blog/ascenza-local-sites/product.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      cms1Image: file(relativePath: { eq: "blog/ascenza-local-sites/cms1.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      cms2Image: file(relativePath: { eq: "blog/ascenza-local-sites/cms2.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      gatsbyArchitectureImage: file(
        relativePath: { eq: "blog/ascenza-local-sites/gatsby-architecture.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
    }
  `);

  return (
    <LayoutBlogPost
      title="Caso de Estudo: Sites locais ASCENZA"
      date="Setembro 28, 2020"
      heroImageFluid={data.heroImage.childImageSharp.fluid}
    >
      <SEO
        title="Sites locais ASCENZA - Mosca Digital"
        description="Caso de Estudo dos sites locais ASCENZA"
        url="https://moscadigital.pt/case-study-ascenza-local-sites"
        imageUrl={data.hp1Image.childImageSharp.fixed.src}
      />
      <CaseStudySummary
        images={[
          data.hp1Image.childImageSharp,
          data.hp2Image.childImageSharp,
          data.hp3Image.childImageSharp,
          data.productsImage.childImageSharp,
          data.productImage.childImageSharp,
        ]}
        clientName="ASCENZA"
        scope="Sites, CMS e migração de dados"
        technologies={
          <>
            <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">
              Gatsby
            </a>
            ,{' '}
            <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
              React
            </a>
            ,{' '}
            <a href="https://www.sanity.io/" target="_blank" rel="noreferrer">
              Sanity
            </a>
            ,{' '}
            <a href="https://www.netlify.com/" target="_blank" rel="noreferrer">
              Netlify
            </a>
            ,{' '}
            <a href="https://www.sketch.com/" target="_blank" rel="noreferrer">
              Sketch
            </a>
            ,{' '}
            <a href="https://www.invisionapp.com/" target="_blank" rel="noreferrer">
              Invision
            </a>
          </>
        }
        challenges="Vários sites existentes, base de código comum, migração de dados, várias línguas, CMS complexo, alta performance"
        websites={
          <>
            <a href="https://ascenza-pt-ae4wj3jn.netlify.app/" target="_blank" rel="noreferrer">
              www.ascenza.pt
            </a>
            ,{' '}
            <a href="https://ascenza-es-r44mjt34.netlify.app/" target="_blank" rel="noreferrer">
              www.ascenza.es
            </a>
            ,{' '}
            <a href="https://ascenza-fr-pu23hpyg.netlify.app/" target="_blank" rel="noreferrer">
              www.ascenza.fr
            </a>
            ,{' '}
            <a href="https://ascenza-it-35k7uvx7.netlify.app/" target="_blank" rel="noreferrer">
              www.ascenza.it
            </a>
          </>
        }
      ></CaseStudySummary>
      <p className="first-letter">
        A ASCENZA, uma empresa multinacional do sector de produtos agrícolas, chegou-nos com um
        desafio muito interessante: eles estavam num processo de rebranding e queriam usar isso como
        oportunidade para criarem sites novos para os países onde estavam presentes, quatro dos
        quais (Portugal, Espanha, França e Itália) já tinham sites cujos conteúdos deveriam ser
        mantidos.
      </p>
      <h3>Descoberta e Wireframing</h3>
      <p>
        Uma vez que os sites existentes tinham tanto em comum quanto diferenças, o primeiro passo
        foi analisar os sites existentes e encontrar uma estrutura para o novo site onde o conteúdo
        de todos esses sites diferentes pudesse viver. Usando o{' '}
        <a href="https://www.sketch.com/" target="_blank" rel="noreferrer">
          Sketch
        </a>{' '}
        para desenhar as wireframes e o{' '}
        <a href="https://www.invisionapp.com/" target="_blank" rel="noreferrer">
          Invision
        </a>{' '}
        para as partilhar, conseguimos iterar rapidamente junto com as equipas dos países em questão
        para encontrar uma solução que funcionasse para eles.
      </p>
      <figure>
        <a href={data.wireframesImage.childImageSharp.fixed.src} data-attribute="SRL">
          <Img fluid={data.wireframesImage.childImageSharp.fluid} alt="" />
        </a>
        <figcaption>Um exemplo de uma das wireframes desenhadas</figcaption>
      </figure>
      <p>
        Uma vez confiantes na nossa nova estrutura do site, começámos a trabalhar no design do site.
      </p>
      <figure>
        <a href={data.hp2Image.childImageSharp.fixed.src} data-attribute="SRL">
          <Img fluid={data.hp2Image.childImageSharp.fluid} alt="" />
        </a>
        <figcaption>A maquete correspondente à wireframe acima</figcaption>
      </figure>
      <h3>CMS e migração de dados</h3>
      <p>
        Com a estrutura do site praticamente decidida, começamos a trabalhar no CMS (Content
        Management System) e na importação dos dados dos sites antigos para os novos.
      </p>
      <p>
        Para o CMS, decidimos usar o{' '}
        <a href="https://www.sanity.io/" target="_blank" rel="noreferrer">
          Sanity
        </a>
        , uma vez que este se foca em dados estruturados, o que combinava perfeitamente com o tipo
        de dados que a ASCENZA tinha. Além disso, o Sanity tem uma interface extremamente intuitiva
        e de alta performance para os editores, e é altamente costumizável, o que nos permitiu
        construir o CMS de acordo com as necessidades dos editores.
      </p>
      <figure>
        <a href={data.cms1Image.childImageSharp.fixed.src} data-attribute="SRL">
          <Img fluid={data.cms1Image.childImageSharp.fluid} alt="" />
        </a>
        <figcaption>Screenshot do CMS que criámos para a ASCENZA</figcaption>
      </figure>
      <figure>
        <a href={data.cms2Image.childImageSharp.fixed.src} data-attribute="SRL">
          <Img fluid={data.cms2Image.childImageSharp.fluid} alt="" />
        </a>
        <figcaption>Screenshot da interface de edição de artigos do CMS</figcaption>
      </figure>
      <p>
        Em paralelo ao CMS, começámos a trabalhar nos scripts para converter os dados do formato
        antigo dos 4 sites para o formato do novo site, e importá-los. Usámos também este passo da
        importação para limpar alguns dos dados, como substituir instâncias da palavra Sapec (o nome
        antigo do nosso cliente) por ASCENZA, ou substituindo o texto em letras maiúsculas (uma má
        prática, e.g., "TEXTO EM CAPS") com texto em caixa normal (e.g. "Texto em caixa normal"),
        quando aplicável.
      </p>
      <p>
        Assim que o CMS e os scripts de importação foram testados e validados pela ASCENZA, fizemos
        a importação e entregámos o CMS à ASCENZA, para que pudessem começar a melhorar os dados
        importados e adicionar novos dados ao novo site quando necessário.
      </p>
      <h3>Implementação e entrega</h3>
      <p>
        Enquanto o nosso cliente ia melhorando os dados no CMS, e agora que o design estava
        terminado e validado, entrámos na última fase do projeto, construir os novos sites.
      </p>
      <p>
        Decidimos usar o{' '}
        <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">
          Gatsby
        </a>
        , uma framework{' '}
        <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
          React
        </a>{' '}
        open source, para construir os sites. Ao contrário de frameworks tradicionais como o
        Wordpress que requerem um servidor completo para correr um servidor web que constrói as
        páginas a cada pedido (e como tal são tipicamente lentos, difíceis de escalar e pouco
        seguros), sites construídos com o Gatsby são só ficheiros estáticos que podem ser alojados
        facilmente num serviço como o{' '}
        <a href="https://www.netlify.com/" target="_blank" rel="noreferrer">
          Netlify
        </a>
        , e como tal têm uma performance, segurança e escalabilidade incríveis.
      </p>
      <figure>
        <a href={data.gatsbyArchitectureImage.childImageSharp.fixed.src} data-attribute="SRL">
          <Img fluid={data.gatsbyArchitectureImage.childImageSharp.fluid} alt="" />
        </a>
        <figcaption>Arquitetura de um site Gatsby/Netlify/Sanity</figcaption>
      </figure>
      <p>
        De forma a colocarmos algo nas mãos do nosso cliente o mais rápido possível para ser testado
        e validado, concentrámo-nos em implementar uma parte do site de cada vez, a começar pelas
        páginas principais, como a página inicial, a página dos produtos e a página de contactos.
        Isso também significou que mesmo ainda com algumas páginas e funcionalidades por
        implementar, já tínhamos um site completo que poderia ter sido lançado antes do prazo se a
        ASCENZA assim o quisesse.
      </p>
      <p>
        Uma vez que a ASCENZA estava satisfeita com os dados e que concluímos a implementação do
        site, estávamos prontos para o lançamento.
      </p>
      <p>
        Para começar, isto significou implementar sistemas de monitorização para sabermos caso o
        site ficasse em baixo por algum motivo, e para sermos notificados caso algum erro
        acontecesse em produção. Implementámos também uma estratégia de backup para os dados no CMS,
        e finalmente configurámos analytics nos sites para que a ASCENZA pudesse saber quantos
        utilizadores visitaram os sites, de onde estes vieram e o que estavam à procura.
      </p>
      <p>
        Feito isto, o restante do lançamento foi muito simples. O nosso ambiente de staging, que era
        onde a ASCENZA validava os sites, já estava no Netlify tal como o ambiente de produção, ou
        seja, o lançamento reduziu-se apenas a apontar os novos domínios para o Netlify, e pudémos
        estar confiantes de que não só o site iria funcionar tal como durante a validação, mas que
        este aguentaria com qualquer tráfego que recebêssemos gráças à incrível dupla de
        Gatsby+Netlify.
      </p>{' '}
      <figure>
        <a href={data.hp1Image.childImageSharp.fixed.src} data-attribute="SRL">
          <Img fluid={data.hp1Image.childImageSharp.fluid} alt="" />
        </a>
        <figcaption>Screenshot da página inicial de um dos sites implementados</figcaption>
      </figure>
      <h3>Conclusion</h3>
      <p>
        A 5 de Agosto de 2020, apenas 5 meses após o início do desenvolvimento, a ASCENZA viu serem
        lançados 4 novos sites seguros, escaláveis e de alta performance (
        <a href="https://ascenza-pt-ae4wj3jn.netlify.app/" target="_blank" rel="noreferrer">
          www.ascenza.pt
        </a>
        ,{' '}
        <a href="https://ascenza-es-r44mjt34.netlify.app/" target="_blank" rel="noreferrer">
          www.ascenza.es
        </a>
        ,{' '}
        <a href="https://ascenza-fr-pu23hpyg.netlify.app/" target="_blank" rel="noreferrer">
          www.ascenza.fr
        </a>
        ,{' '}
        <a href="https://ascenza-it-35k7uvx7.netlify.app/" target="_blank" rel="noreferrer">
          www.ascenza.it
        </a>
        ), repletos de conteúdo importado (e melhorado) dos seus sites antigos, e com um CMS
        poderoso e fácil de usar, que lhes permitirá controlar o conteúdo dos seus sites por muito
        tempo após o desenvolvimento.
      </p>
      <p>
        Estamos orgulhosos de termos sido escolhidos pela ASCENZA e orgulhosos do trabalho que
        entregámos.
      </p>
      <p>
        E cada projeto ensina-nos novas lições que levamos connosco e aplicamos nos próximos
        projectos por forma a entregar soluções ainda melhores ainda mais rápidamente.
      </p>
      <p>Será o seu projeto o próximo?</p>
    </LayoutBlogPost>
  );
};

export default withI18n(CaseStudyAscenzaLocalSitesPage, 'pt');
